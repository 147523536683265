// @ts-check
import {TextField} from "@mui/material";
import {bool, object, oneOf, string} from "prop-types";
import React from "react";
import {useController} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {makeStyles} from "tss-react/mui";

/** @typedef {import('react-hook-form').Control<any, any>} Control */

const useStyles = makeStyles()((theme) => ({
    helperTextError: {
        fontSize: "0.7rem",
        color: theme.palette.error.main
    },
    fullWidth: {
        width: "100%"
    }
}));

/**
 * A reusable controlled MUI TextField to use with RHF
 * @param {object} props
 * @param {string} props.label The TextField label
 * @param {string} props.name Text field name
 * @param {Control} props.control RHF's control object
 * @param {string} [props.type=text] The TextField type
 * @param {"primary"|"secondary"} [props.color="primary"] MUI color for the TextField
 * @param {object} [props.inputProps] MUI inputProps for the TextField
 * @param {object} [props.InputProps] MUI InputProps for the TextField
 * @param {boolean} [props.required=false] Determines if the field is required
 * @param {string} [props.classesStyle] The styles for the classes prop of the TextField
 * @param {string} [props.className] The className for the className prop of the TextField
 * @param {boolean} [props.disabled=false] Wether the TextField is disable
 * @param {boolean} [props.shrinkLabel = true] Wether the Label is shrunk.
 * @param {string} [props.placeholder] The input placeholder
 * @param {boolean} [props.multiline=false] The multiline input
 * @return {JSX.Element} The controlled TextField
 */
export const ControlledTextField = ({
    control,
    name,
    color = "primary",
    inputProps,
    label,
    disabled = false,
    type = "text",
    InputProps,
    required = false,
    classesStyle,
    placeholder,
    shrinkLabel = true,
    multiline = false
}) => {
    const {t} = useTranslation();
    const {classes, cx} = useStyles();
    const {
        field,
        fieldState: {error}
    } = useController({
        name,
        control,
        defaultValue: ""
    });

    return (
        <TextField
            classes={{root: cx(classes.fullWidth, classesStyle && classesStyle)}}
            color={color}
            FormHelperTextProps={{
                className: classes.helperTextError
            }}
            multiline={multiline}
            name={name}
            variant="standard"
            {...field}
            disabled={disabled}
            error={error ? true : false}
            helperText={error ? t(error.message) : null}
            InputLabelProps={{shrink: shrinkLabel}}
            inputProps={inputProps}
            InputProps={InputProps}
            label={label}
            placeholder={placeholder}
            required={required}
            type={type}
        />
    );
};
ControlledTextField.propTypes = {
    color: oneOf(["primary", "secondary"]),
    control: object.isRequired,
    name: string.isRequired,
    label: string.isRequired,
    inputProps: object,
    disabled: bool,
    type: string,
    InputProps: object,
    required: bool,
    classesStyle: string,
    placeholder: string,
    shrinkLabel: bool,
    multiline: bool
};
