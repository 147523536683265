import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    wrapper: {
        "padding": "3rem 2rem 2rem",
        "width": "560px",
        "borderRadius": 0,
        "& .MuiDialogTitle-root": {
            color: theme.palette.text.primary,
            display: "flex",
            justifyContent: "center",
            fontWeight: theme.typography.fontWeightMedium
        },
        "& .MuiDialogContent-root": {
            color: theme.palette.text.primary,
            display: "flex",
            justifyContent: "center",
            fontSize: "0.875rem",
            textAlign: "center"
        },
        "& .MuiDialogActions-root": {
            justifyContent: "space-between"
        }
    },
    closeIcon: {
        fontSize: "1.5rem",
        position: "absolute",
        top: theme.spacing("l"),
        right: theme.spacing("l"),
        cursor: "pointer"
    }
}));
