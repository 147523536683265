// @ts-check
import {Close} from "@mui/icons-material";
import {Box, Checkbox, Chip, FormControl, FormHelperText, InputLabel, TextField, Tooltip} from "@mui/material";
import {Autocomplete} from "@mui/material";
import {array, bool, object, string} from "prop-types";
import React from "react";
import {Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";

import useStyles from "../controlled_form_styles";

/**
 * A reusable controlled MUI Autocomplete for multiple values to use with RHF
 *
 * @param {object} props
 * @param {{message: string, type: string, ref: {name: string}}} [props.errors] RHF's control object
 * @param {object} props.control RHF's control object
 * @param {Array<ItemType>} props.items RHF's control object
 * @param {string} props.name The Controller name for RHF
 * @param {string} props.title The Input's title
 * @param {string} [props.placeholder=""] The Input's placeholder
 * @param {string} [props.classesStyle] The optional className for the FormControl
 * @param {boolean} [props.hasTooltip=false] The optional className for the FormControl
 * @return {React.ReactElement}
 */
export const ControlledAutocompleteMultiple = ({
    errors,
    control,
    items,
    name,
    title,
    placeholder = "",
    classesStyle,
    hasTooltip = false
}) => {
    const {t} = useTranslation();
    const hasError = Boolean(errors);

    const {classes, cx} = useStyles();
    return (
        <FormControl classes={{root: cx(classesStyle && classesStyle)}} error={hasError} variant="standard">
            <InputLabel shrink>{title}</InputLabel>
            <Controller
                control={control}
                name={name}
                render={({field: {value, onChange}}) => (
                    <Autocomplete
                        autoHighlight
                        classes={{
                            noOptions: classes.noOptions,
                            inputRoot: classes.fullHeight,
                            endAdornment: classes.endAdornment,
                            listbox: classes.listbox
                        }}
                        clearIcon={<Close className={classes.clearIcon} />}
                        closeText={t("App.close")}
                        data-testid={`multiple-selector-${name}`}
                        disableCloseOnSelect
                        fullWidth
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, {value}) => option.value === value}
                        limitTags={1}
                        multiple
                        openText={t("App.open")}
                        options={items}
                        renderInput={(params) => (
                            <TextField
                                error={hasError}
                                InputLabelProps={{shrink: true}}
                                placeholder={value?.length === 0 ? placeholder : ""}
                                variant="standard"
                                {...params}
                                className={classes.inputAutocomplete}
                            />
                        )}
                        renderOption={({className, ...restProps}, option, {selected}) => {
                            // Track the order of selection to display it next to the selected option
                            const selectedOptionsIds = value.map((option) => option.value);
                            const selectionOrder = selected && selectedOptionsIds.indexOf(option.value) + 1;

                            return (
                                <Box
                                    {...restProps}
                                    className={cx(className, classes.menu, classes.withCheckBox, classes.noLeftPadding)}
                                    component="li"
                                    key={option.id || option.value}
                                >
                                    <Tooltip
                                        arrow
                                        classes={{tooltip: classes.tooltip, tooltipArrow: classes.tooltip}}
                                        PopperProps={{disablePortal: true}}
                                        title={`${hasTooltip ? option.label : ""}`}
                                    >
                                        <div className={classes.ellipsis}>
                                            <Checkbox checked={selected} className={classes.checkbox} size="small" />
                                            {option.label} {selected && `(${selectionOrder})`}
                                        </div>
                                    </Tooltip>
                                </Box>
                            );
                        }}
                        renderTags={(value) => {
                            const names = value.map((option) => option.label).join(" | ");
                            if (value.length > 1)
                                return (
                                    <Tooltip
                                        arrow
                                        classes={{tooltip: classes.tooltip, tooltipArrow: classes.tooltip}}
                                        PopperProps={{disablePortal: true}}
                                        title={`${names}`}
                                    >
                                        <div className={classes.chipWrapper}>
                                            <Chip classes={{root: classes.chip, label: classes.chipLabel}} label={`${value[0].label}`} />
                                            <span className={classes.pipeDivider}> | </span>
                                            <Chip classes={{root: classes.chip}} className={classes.ellipsis} label={`${value[1].label}`} />
                                            {value.length > 2 && <span>{`(+${value.length - 2})`}</span>}
                                        </div>
                                    </Tooltip>
                                );
                            return value.map((option) => (
                                <Chip classes={{root: classes.chip}} key={`${option?.label}-${option?.value}`} label={option.label} />
                            ));
                        }}
                        size="small"
                        value={value}
                        onChange={(event, selectedOptions) => onChange(selectedOptions)}
                    />
                )}
            />
            {hasError && <FormHelperText className={classes.error}>{t(errors.message)}</FormHelperText>}
        </FormControl>
    );
};
ControlledAutocompleteMultiple.propTypes = {
    errors: object,
    control: object.isRequired,
    items: array.isRequired,
    name: string.isRequired,
    title: string.isRequired,
    placeholder: string,
    classesStyle: string,
    hasTooltip: bool
};
