import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    input: {
        minWidth: "5rem",
        fontSize: "0.875rem",
        color: theme.palette.text.primary,
        display: "flex",
        alignItems: "center"
    },
    placeholder: {
        opacity: 1,
        color: theme.palette.grey[500]
    },
    menuWrapper: {
        maxHeight: "24rem"
    },
    menu: {
        "fontSize": "0.875rem",
        "& .MuiSvgIcon-fontSizeSmall": {
            fontSize: theme.spacing("m")
        }
    },
    withCheckBox: {
        padding: "0 0.625rem"
    },
    closeIcon: {
        "fontSize": theme.spacing("m"),
        "marginLeft": theme.spacing("s"),
        "borderRadius": theme.spacing("s"),
        "border": `1px solid ${theme.palette.grey[200]}`,
        "&:hover": {
            background: theme.palette.grey[100]
        }
    },
    checkbox: {
        color: theme.palette.primary.main
    },
    text: {
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    fullWidth: {
        width: "100%"
    }
}));
