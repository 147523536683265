import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    input: {
        minWidth: "5rem",
        fontSize: "0.875rem",
        color: theme.palette.text.primary,
        display: "flex",
        alignItems: "center"
    },
    inputAutocomplete: {
        "height": "50px",
        "& input": {
            "height": "58px",
            "&::placeholder": {
                opacity: 1,
                color: theme.palette.grey[500]
            },
            "minWidth": "10px !important"
        },
        "& .Mui-error": {
            color: theme.palette.error.main
        }
    },
    menuWrapper: {
        maxHeight: "24rem",
        fontSize: "0.875rem !important"
    },
    menuWrapperAutocomplete: {
        fontSize: "0.875rem !important"
    },
    menu: {
        "fontSize": "0.875rem",
        "cursor": "pointer",
        "& .MuiSvgIcon-fontSizeSmall": {
            fontSize: theme.spacing("m")
        },
        "&:hover": {
            backgroundColor: theme.palette.grey[100]
        }
    },
    menuHeader: {
        fontSize: "0.75rem",
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.primary
    },
    withCheckBox: {
        padding: "0 0.625rem"
    },
    noLeftPadding: {
        paddingLeft: "0"
    },
    closeIcon: {
        "fontSize": theme.spacing("m"),
        "marginLeft": theme.spacing("s"),
        "borderRadius": theme.spacing("s"),
        "border": `1px solid ${theme.palette.grey[200]}`,
        "&:hover": {
            background: theme.palette.grey[100]
        }
    },
    listbox: {
        "& li": {
            height: "40px",
            minHeight: "40px",
            paddingLeft: "10px"
        }
    },
    checkbox: {
        color: theme.palette.primary.main
    },
    text: {
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    fullWidth: {
        width: "100%"
    },
    error: {
        color: theme.palette.error.main,
        fontSize: "0.688rem",
        marginBottom: theme.spacing("s")
    },
    select: {
        "width": "8rem",
        "marginRight": "24px",
        "& .Mui-error": {
            color: theme.palette.error.main
        }
    },
    fullHeight: {height: "100%"},
    flex: {
        display: "flex",
        alignItems: "center"
    },

    noOptions: {
        color: `${theme.palette.grey[500]} !important`,
        fontSize: "14px"
    },
    endAdornment: {
        "marginTop": "8px",
        "& .MuiIconButton-root:hover": {
            backgroundColor: "transparent"
        }
    },
    clearIcon: {
        "fontSize": "18px",
        "marginLeft": theme.spacing("s"),
        "borderRadius": theme.spacing("s"),
        "border": `1px solid ${theme.palette.grey[200]}`,
        "&:hover": {
            background: theme.palette.grey[100]
        }
    },
    chipWrapper: {
        display: "flex",
        maxWidth: "calc(100% - 20px) !important",
        fontSize: "14px !important",
        alignItems: "center"
    },
    chip: {
        backgroundColor: "transparent !important",
        maxWidth: "calc(100% - 30px) !important",
        fontSize: "14px !important",
        paddingRight: "0 !important"
    },
    option: {
        padding: `0 !important`
    },
    // Date Picker
    inputDate: {
        "fontSize": "0.875rem",
        "color": theme.palette.text.primary,
        "height": "1.7rem",
        "& .MuiIconButton-root": {
            padding: 0
        },
        "& .MuiSvgIcon-root": {
            fontSize: "1.5rem",
            color: theme.palette.primary.main,
            marginLeft: theme.spacing("s")
        },
        "& .MuiInputBase-input.Mui-disabled": {
            display: "none"
        },
        "& .MuiInputBase-root.Mui-disabled": {
            height: "100%"
        },
        "& .Mui-error": {
            color: theme.palette.error.main
        },
        "width": "7rem"
    },
    mtEnd: {
        // marginTop on end item (dateRange, timeRange)
        "marginTop": theme.spacing("m"),
        "& .MuiInput-root": {
            marginTop: 0
        }
    },
    // Radio Group
    radioLabel: {
        fontSize: "0.688rem",
        textTransform: "uppercase",
        fontWeight: theme.typography.fontWeightLight
    },
    radioRoot: {
        "width": "12rem",
        "height": theme.spacing("m"),
        "margin": "1rem 0",
        "left": "-0.5rem",
        "position": "relative",
        "& .MuiRadio-root": {
            padding: theme.spacing("xs")
        }
    },
    placeholder: {color: `${theme.palette.grey[500]}`},
    tooltip: {backgroundColor: `${theme.palette.grey[800]}`},
    checkIcon: {
        color: theme.palette.success.main,
        marginLeft: theme.spacing("s"),
        fontSize: "22px"
    },
    textWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    clearIconSingle: {
        "fontSize": "16px",
        "marginLeft": theme.spacing("s"),
        "marginTop": "2px",
        "borderRadius": theme.spacing("s"), // half of the fontSize
        "border": `1px solid ${theme.palette.grey[200]}`,
        "&:hover": {
            background: theme.palette.grey[100]
        }
    },
    ellipsis: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    thinDivider: {
        margin: "0 !important"
    },
    width100: {
        width: "100%"
    },
    pipeDivider: {
        marginRight: "4px"
    },
    chipLabel: {
        paddingRight: "0 !important"
    }
}));
