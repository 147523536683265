// eslint-disable-next-line simple-import-sort/imports
import isEmpty from "lodash/isEmpty";
import {array, bool, object, string} from "prop-types";
import React from "react";
import {Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";

import useStyles from "../controlled_form_styles";
import {FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup} from "@mui/material";

export const ControlledRadioGroup = ({title, items, errors, hasOwnErrorMessage = true, isDisabled, isRow = true, control, name}) => {
    const {t} = useTranslation();
    const hasError = isEmpty(errors);
    const {classes} = useStyles();

    return (
        <FormControl variant="standard">
            <FormLabel className={classes.title} component="legend">
                {title}
            </FormLabel>
            <Controller
                control={control}
                name={name}
                render={({field}) => {
                    return (
                        <RadioGroup {...field} row={isRow}>
                            {items.map(({value, label}) => {
                                return (
                                    <FormControlLabel
                                        classes={{label: classes.radioLabel, root: classes.radioRoot}}
                                        control={<Radio color="primary" />}
                                        key={value}
                                        label={label}
                                        value={value}
                                    />
                                );
                            })}
                        </RadioGroup>
                    );
                }}
            />
            {hasError && hasOwnErrorMessage && (
                <FormHelperText className={classes.error} error>
                    {t(errors.message)}
                </FormHelperText>
            )}
        </FormControl>
    );
};
ControlledRadioGroup.propTypes = {
    title: string,
    items: array.isRequired,
    errors: object,
    hasOwnErrorMessage: bool,
    isDisabled: bool,
    isRow: bool,
    control: object.isRequired,
    name: string.isRequired
};
