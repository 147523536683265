// @ts-check
import {Edit, MoreVert, OpenInNew} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import PropTypes from "prop-types";
import React, {Fragment, useCallback, useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {FixedSizeList} from "react-window";

import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {
    changeDate,
    changeHighlightedOp,
    changeSelectedOp,
    loadDetailOpAction,
    loadOpManagementScheduleAction,
    setScrollTo
} from "../../../pages/op_management/op_management_actions";
import {scheduleOp} from "../../../pages/op_management/op_management_proptypes";
import {
    selectAllOpenOps,
    selectCoreValues,
    selectSelectedDate,
    selectSessionEditedBy
} from "../../../pages/op_management/op_management_selectors";
import {selectCurrentOrganizationId, selectCurrentUserEmail} from "../../../redux/app_selectors";
import getDurations from "../../../utils/get_durations";
import getInterventions from "../../../utils/get_interventions";
import {getParticipantCategories} from "../../../utils/get_participant_category";
import getRoom from "../../../utils/get_room";
import getTimestamps from "../../../utils/get_timestamps";
import {PERMISSION, useSecurity} from "../../../utils/security";
import {selectFeSettings} from "../../fe_settings/fe_settings_selectors";
import {determineIsEditIconVisible, formatContents} from "../../op_box_horizontal/helpers";
import OpBoxHorizontal from "../../op_box_horizontal/op_box_horizontal";
import {OpDetailsPopover} from "../../op_details_popover/op_details_popover";
import OpEditLayer from "../../op_edit_layer/op_edit_layer";
import {clearEditOpAction, fetchOptionsAction, loadEditOpAction} from "../../op_edit_layer/op_edit_layer_actions";
import {selectEditOpData} from "../../op_edit_layer/op_edit_layer_selectors";
import {selectRoomInfos} from "../../rooms/rooms_selectors";
import DetailDialog from "../../shared/detail_dialog/detail_dialog";
import useStyles from "../search_layer.styles";

const LAYER_INNER_WIDTH = 420;

const CPB = "CPB";
const barHeight = 3.8;

/**
 * render search result component
 *
 * @param {Object} props
 * @param {Array<PlanBox>} props.resultOps
 * @param {Function} props.onOpenDetails
 * @param {number} props.height
 * @param {boolean} props.isBlockscreenVisible
 * @return {React.ReactElement}
 */
export const SearchResult = ({resultOps, onOpenDetails, height, isBlockscreenVisible}) => {
    const {classes, cx} = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const ref = useRef(null); // for action menu
    const innerRef = useRef(null); // for scroll area
    const {isGranted} = useSecurity();
    const {fromISO, areSame, formatFromISO, getLuxonToken} = useContext(DateContext);

    // redux store
    const organizationId = useSelector(selectCurrentOrganizationId);
    const allOpenOps = useSelector(selectAllOpenOps);
    const selectedDate = useSelector(selectSelectedDate);
    const coreValues = useSelector(selectCoreValues);
    const roomInfos = useSelector(selectRoomInfos);
    const editOpData = useSelector(selectEditOpData);
    const {additionalOpInfo, participantCategoriesForHealthcareService} = useSelector(selectFeSettings);
    const sessionEditedBy = useSelector(selectSessionEditedBy);
    const email = useSelector(selectCurrentUserEmail);

    // state
    const [resetScrollbar, setResetScrollbar] = useState(false);
    const [openOpEdit, setOpenOpEdit] = useState(false);
    const [showActionMenu, setShowActionMenu] = useState(null);
    const [selectedOp, setSelectedOp] = useState({id: null, active: null, new: null});
    const [anchorEl, setAnchorEl] = useState(null);
    const [opBoxPosition, setOpBoxPosition] = useState({top: 0, left: 0});

    // Popover
    const isPopoverOpen = Boolean(anchorEl);

    // set scroll Offset back to 0 if filtered ops changing
    useEffect(() => {
        if (innerRef.current) {
            setResetScrollbar(true);
        }
    }, [resultOps]);

    useEffect(() => {
        if (showActionMenu) {
            document.addEventListener("click", handleClickOutside, {capture: true});
        }
        return () => {
            document.removeEventListener("click", handleClickOutside, {capture: true});
        };
    }, [showActionMenu]);

    useEffect(() => {
        // if block screen is visible, force to close the edit layer
        if (isBlockscreenVisible) {
            dispatch(clearEditOpAction());
            setOpenOpEdit(false);
        }
    }, [isBlockscreenVisible]);

    const handleClickOutside = (e) => {
        // doesn't work correctly, always close the menu
        if (ref && ref.current && !ref.current.contains(e.target) && !e.target.closest(".MuiSvgIcon-root")) {
            handleActionClose();
        }
    };

    /**
     * handle to open op details by clicking the op box
     * @param {object} object - Properties wrapper
     * @param {string} object.opId - The id of the appointment
     * @param {Element} object.currentTarget - The clicked element
     */
    const handleClickDetailsOnSearch = ({currentTarget, opId}) => {
        dispatch(loadDetailOpAction(organizationId, opId));
        onOpenDetails(opId);

        const top = currentTarget?.getBoundingClientRect().top;
        const left = currentTarget?.getBoundingClientRect().left;
        setOpBoxPosition({top, left});
        const target = currentTarget;
        setTimeout(() => setAnchorEl(target), 500);
    };

    const handleClickEdit = (opId, procedureCode, hcServiceId) => {
        setOpenOpEdit(true);
        dispatch(loadEditOpAction(opId));
        dispatch(fetchOptionsAction(procedureCode, hcServiceId));
    };

    const handleStopEdit = () => {
        setOpenOpEdit(false);
    };

    const handleActionClick = useCallback(
        (e, id) => {
            e.stopPropagation();
            setShowActionMenu(true);
            const activeOp = resultOps.find((item) => item.id === id);
            const newOp = allOpenOps.find((item) => item.id === id);
            setSelectedOp({id: id, new: newOp, active: activeOp});
        },
        [resultOps, allOpenOps]
    );

    const handleActionClose = () => {
        setShowActionMenu(false);
        setSelectedOp({id: null, active: null, new: null});
        // Clear highlighted Op
        dispatch(changeHighlightedOp({id: null, opRoom: null, opStart: null}));
    };

    /**
     * Move to canvas of clicked op
     * @param {String} type - "new" or "active"
     */
    const handleMoveCanvas = (type) => {
        let opStart = null;
        let opRoom = null;
        if (type === "new") {
            opStart = getTimestamps(selectedOp.new).roomLockStart;
            opRoom = getRoom(selectedOp.new);
        } else if (type === "active") {
            opStart = getTimestamps(selectedOp.active).roomLockStart;
            opRoom = getRoom(selectedOp.active);
        }

        if (!areSame(fromISO(opStart), selectedDate, "day")) {
            dispatch(loadOpManagementScheduleAction(organizationId, formatFromISO(opStart, DATE_FORMATS.SYSTEM_DATE)));
            dispatch(changeDate(fromISO(opStart)));
        }
        // Set highlighted Op
        dispatch(changeHighlightedOp({id: selectedOp.id, opRoom, opStart}));

        // Set scrollTo as true
        dispatch(setScrollTo(true));
    };

    /**
     * Handler to close the popover with the OP Details
     */
    const handleClosePopover = () => {
        setAnchorEl(null);
        setSelectedOp({id: null, active: null, new: null});
        dispatch(changeSelectedOp());
    };

    const barArrays = [];

    const sortedResultOps = resultOps.sort((a, b) => {
        const {roomLockStart: aStart} = getTimestamps(a);
        const {roomLockStart: bStart} = getTimestamps(b);
        return fromISO(aStart) < fromISO(bStart) ? -1 : 1;
    });
    for (const [i, opData] of sortedResultOps.entries()) {
        // prepare properties
        const {
            id,
            _team: team,
            _patient: patient,
            _interventions: interventions,
            _healthcareService: healthcareService,
            _priority: priority,
            _status: status,
            _styles: styles,
            _internalTimestamps: internalTimestamps,
            _medicalApproval: medicalApproval,
            _isEditable: isEditable,
            _surgeonPresenting: surgeonPresenting
        } = opData;

        const hcServiceId = healthcareService.reference;
        const luxonTokenTime = getLuxonToken(DATE_FORMATS.TIME);

        const contents = formatContents({
            team,
            participantCategories: getParticipantCategories({
                participantCategoriesForHealthcareService,
                hcServiceId
            }),
            patient,
            intervention: getInterventions(opData),
            internalTimestamps,
            isCpbVisible: additionalOpInfo?.includes(CPB),
            medicalApproval,
            surgeonPresenting,
            luxonTokenTime
        });

        // Calculate Bar length
        const barWidth = (getDurations(opData).duraRoomLockPost.refEnd * coreValues.widthPerHour) / (60 * 60); // width in px (1 hour = 120px)
        const barWidthPercent = (barWidth / LAYER_INNER_WIDTH) * 100;
        const {roomLockStart} = getTimestamps(opData);

        const shape = {
            barHeight,
            topPosition: "0.5rem",
            startPercent: 15,
            endPercent: barWidthPercent + 15
        };
        const procedureCode = interventions.main?.[0].code;

        const isEditIconVisibleNew = determineIsEditIconVisible({
            isPublished: false,
            isEditingByOther: sessionEditedBy && email !== sessionEditedBy
        });
        const isEditableWithPermission = isGranted(PERMISSION.MODIFY_PLAN) && isEditable;
        barArrays.push(
            <div className={classes.opBoxWrapper} style={{top: i * 80 + "px"}}>
                <div className={classes.dateText}>{formatFromISO(roomLockStart, DATE_FORMATS.DATE_MEDIUM)}</div>
                <OpBoxHorizontal
                    contents={contents}
                    duration={getDurations(opData)}
                    edit={{isEditable: false, isEditIconVisible: false}} // In the op box, edit icon is not visible
                    hcServiceId={hcServiceId}
                    id={id}
                    isDeemphasized={false}
                    isSearch
                    priority={priority}
                    procedureCode={procedureCode}
                    shape={shape}
                    showDiscipline
                    status={status}
                    styles={styles}
                    onClick={handleClickDetailsOnSearch}
                    onClickEdit={handleClickEdit}
                />
                <div className={classes.actionButton} data-testid="filterEditAppointment">
                    <IconButton
                        className={cx({[classes.selected]: id === selectedOp.id})}
                        data-testid={`action-btn-${id}`}
                        size="large"
                        onClick={(e) => handleActionClick(e, id)}
                    >
                        <MoreVert color="primary" fontSize="small" />
                    </IconButton>
                    <div
                        className={cx(classes.menu, {
                            [classes.unvisible]: !(showActionMenu && selectedOp.id === id)
                        })}
                    >
                        <div className={classes.actionMenuWrapper} ref={ref}>
                            {selectedOp.active && (
                                <div className={classes.actionMenu}>
                                    <div className={classes.active}>{t("SearchLayer.active")}</div>
                                    <div className={classes.ml1}>
                                        {t("SearchLayer.dateRoomTime", {
                                            date: formatFromISO(getTimestamps(selectedOp.active).roomLockStart, DATE_FORMATS.DATE_SHORT),
                                            room:
                                                roomInfos.find((el) => el.id === getRoom(selectedOp.active))?.name ||
                                                getRoom(selectedOp.active),
                                            time: formatFromISO(getTimestamps(selectedOp.active).roomLockStart, DATE_FORMATS.TIME)
                                        })}
                                    </div>
                                    <OpenInNew
                                        className={cx(classes.icon, classes.ml1, classes.pointer)}
                                        color="primary"
                                        data-testid="jumpToAktiv"
                                        onClick={() => handleMoveCanvas("active")}
                                    />
                                </div>
                            )}
                            {selectedOp.new && (
                                <div className={classes.actionMenu}>
                                    <div className={classes.new}>{t("SearchLayer.new")}</div>
                                    <div className={classes.ml1}>
                                        {t("SearchLayer.dateRoomTime", {
                                            date: formatFromISO(getTimestamps(selectedOp.new).roomLockStart, DATE_FORMATS.DATE_SHORT),
                                            room:
                                                roomInfos.find((el) => el.id === getRoom(selectedOp.new))?.name || getRoom(selectedOp.new),
                                            time: formatFromISO(getTimestamps(selectedOp.new).roomLockStart, DATE_FORMATS.TIME)
                                        })}
                                    </div>
                                    <OpenInNew
                                        className={cx(classes.icon, classes.ml1, classes.pointer)}
                                        color="primary"
                                        data-testid="jumpToNeu"
                                        onClick={() => handleMoveCanvas("new")}
                                    />
                                    {isEditIconVisibleNew && (
                                        // @ts-ignore
                                        <Edit
                                            className={cx(classes.icon, classes.ml1, classes.pointer, {
                                                [classes.disabled]: !isEditableWithPermission
                                            })}
                                            color="primary"
                                            data-testid={`edit-btn-${id}`}
                                            title={!selectedOp.new._isEditable ? t("SearchLayer.notEditable") : null}
                                            onClick={() => isEditableWithPermission && handleClickEdit(id, procedureCode, hcServiceId)}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    const rows = (barArrays, props) => {
        return barArrays[props.index];
    };

    return (
        <div className={classes.result}>
            <Fragment>
                <div className={classes.resultOps}>
                    <OpDetailsPopover
                        anchorEl={anchorEl}
                        handleClosePopover={handleClosePopover}
                        isBlockscreenVisible={isBlockscreenVisible}
                        isPopoverOpen={isPopoverOpen}
                        opBoxPosition={opBoxPosition}
                    />
                    <FixedSizeList
                        height={height} // header of result block = 75px
                        initialScrollOffset={innerRef.current ? innerRef.current.scrollOffset : 0}
                        innerRef={innerRef}
                        itemCount={barArrays.length}
                        itemSize={80}
                        style={{overflowX: "hidden"}}
                        width={LAYER_INNER_WIDTH}
                        onScroll={({scrollOffset}) => {
                            if (resetScrollbar) {
                                innerRef.current.scrollOffset = 0;
                                setResetScrollbar(false);
                            } else {
                                innerRef.current.scrollOffset = scrollOffset;
                            }
                        }}
                    >
                        {(props) => rows(barArrays, props)}
                    </FixedSizeList>
                </div>
            </Fragment>
            {openOpEdit && editOpData?.length && (
                <DetailDialog
                    isBlockscreenVisible={isBlockscreenVisible}
                    open={openOpEdit}
                    styles={{root: classes.detailDialogRoot}}
                    onClose={handleStopEdit}
                >
                    <OpEditLayer originalOpData={editOpData[0]} onClose={handleStopEdit} />
                </DetailDialog>
            )}
        </div>
    );
};
SearchResult.propTypes = {
    resultOps: PropTypes.arrayOf(scheduleOp),
    onOpenDetails: PropTypes.func.isRequired,
    index: PropTypes.number,
    height: PropTypes.number.isRequired,
    isBlockscreenVisible: PropTypes.bool.isRequired
};

export default SearchResult;
