import {Settings} from "@mui/icons-material";
import {Badge} from "@mui/material";
import {string} from "prop-types";
import React from "react";
import {useSelector} from "react-redux";

import {selectSurgeryAssignmentWarningCount} from "../../pages/surgery_assignment/surgery_assignment_selectors";
import {useStyles} from "./settings_menu_icon_styles";

/**
 * The settings icon in the left Menu
 *
 * @param {object} props
 * @param {string} props.className The className to style the Settings icon
 * @return {React.ReactElement}
 */
export const SettingsMenuIcon = ({className}) => {
    const {classes} = useStyles();
    const hasWarnings = Boolean(useSelector(selectSurgeryAssignmentWarningCount));

    return (
        <Badge
            badgeContent=" "
            classes={{badge: classes.badge, anchorOriginTopRightCircular: classes.dotPosition}}
            color="error"
            invisible={!hasWarnings}
            overlap="circular"
        >
            <Settings className={className} />
        </Badge>
    );
};

SettingsMenuIcon.propTypes = {
    className: string.isRequired
};
