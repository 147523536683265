// @ts-check
import {ArrowDropDown, ArrowDropUp} from "@mui/icons-material";
import {Button, FormControlLabel, Switch} from "@mui/material";
import cloneDeep from "lodash/cloneDeep";
import uniqBy from "lodash/uniqBy";
import PropTypes from "prop-types";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {useFetchPriorities} from "../../../hooks/useFetchPriorities";
import {loadSearchScheduleAction} from "../../../pages/op_management/op_management_actions";
import {selectCurrentOrganizationId, selectCurrentUserEmail} from "../../../redux/app_selectors";
import searchPatientByText from "../../../utils/search_patient_by_text";
import {DisciplineSelect, SurgeonsMultiSelect} from "../../disciplines";
import {selectAllFullNamesArray} from "../../private_data/private_data_selectors";
import {selectRoomInfos} from "../../rooms/rooms_selectors";
import CommonSelector from "../../shared/common_selector/common_selector";
import DateRange from "../../shared/date_range/date_range";
import {GENDER} from "../../shared/gender/gender";
import SearchTextField from "../../shared/search_text_field/search_text_field";
import useStyles from "../search_layer.styles";

const NONE = "none";
/**
 * @typedef {Object} PlanningDate
 * @property {DateTimeType} start
 * @property {DateTimeType} end
 * @property {DateTimeType} min
 * @property {DateTimeType} max
 */

/**
 * Render SearchFilter component
 * @param {Object} props
 * @param {Function} props.onFullOption
 * @param {String} props.searchTrigger
 * @return {React.ReactElement}
 */
export const SearchFilter = ({onFullOption, searchTrigger}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {startOf, endOf, now, plusDT, minusDT, format} = useContext(DateContext);

    // Redux
    const organizationId = useSelector(selectCurrentOrganizationId);
    const roomInfos = useSelector(selectRoomInfos);
    const email = useSelector(selectCurrentUserEmail);
    const allPatientNames = useSelector(selectAllFullNamesArray({type: "patientAll"}));
    const {prioritiesList} = useFetchPriorities(organizationId);

    // State
    const [showAllOptions, setShowAllOptions] = useState(false);
    const [patientName, setPatientName] = useState("");

    // list* has array of Object {label, key}
    const [selectedDisciplines, setSelectedDisciplines] = useState([]);

    const [selectedSurgeons, setSelectedSurgeons] = useState([]);

    const [selectedRooms, setSelectedRooms] = useState([]);

    const [urgency, setUrgency] = useState("");

    const [ageRange, setAgeRange] = useState([0, 120]);

    const [plannedDate, setPlannedDate] = useState({
        start: startOf(now(), "day"),
        end: endOf(plusDT(now(), "day", 28), "day"),
        min: minusDT(now(), "month", 1),
        max: plusDT(now(), "month", 1)
    });

    const [progress, setProgress] = useState("");

    const [refetch, setRefetch] = useState(false);

    const listProgress = [
        {value: "preparation", label: t("SearchLayer.planned")},
        {value: "in-progress", label: t("SearchLayer.inProgress")},
        {value: "completed", label: t("SearchLayer.done")},
        {value: "not-done", label: t("SearchLayer.cancelled")}
    ];

    const [status, setStatus] = useState(""); // multi-select
    const listStatus = [{value: "locked", label: t("SearchLayer.locked")}];

    const [gender, setGender] = useState("");
    const listGender = Object.keys(GENDER).map((gender) => ({value: gender, label: t(`Gender.${gender}`)}));

    const [showFirstOp, setShowFirstOp] = useState(false);

    useEffect(() => {
        handleSearch();
    }, [searchTrigger]);

    useEffect(() => {
        if (refetch) {
            handleSearch();
            setRefetch(false);
        }
    }, [refetch]);

    /**
     * load searchOps between selected plannedDate.start and plannedDate.end
     */
    const handleSearch = () => {
        const fromDate = format(plannedDate.start, DATE_FORMATS.SYSTEM_DATE);
        const toDate = format(plannedDate.end, DATE_FORMATS.SYSTEM_DATE);

        // Search patientIds by user input text
        const filteredPatientIds = searchPatientByText(allPatientNames, patientName);

        // If Search result is 0, set "none"
        if (filteredPatientIds.length === 0) {
            filteredPatientIds.push(NONE);
        }
        dispatch(
            loadSearchScheduleAction({
                organizationId,
                email,
                fromDate,
                toDate,
                patientIds: patientName.length ? filteredPatientIds : undefined,
                hcServiceIds: selectedDisciplines.length ? selectedDisciplines : undefined,
                locationIds: selectedRooms.length ? selectedRooms : undefined,
                locked: status && status === "locked" ? true : undefined,
                ageFrom: ageRange[0],
                ageTo: ageRange[1],
                gender: gender ? gender : undefined,
                status: progress ? progress : undefined,
                onlyFirstAppointment: showFirstOp,
                priorities: urgency ? [urgency] : undefined,
                practitionerIds: selectedSurgeons.length ? selectedSurgeons : undefined
            })
        );
    };

    const handleReset = () => {
        setPatientName("");
        setSelectedDisciplines([]);

        // Prepair from/toDate
        const fromDate = now();
        const toDate = plusDT(now(), "day", 28);

        // Reset all states
        setPlannedDate((/** @type {PlanningDate} */ prevState) => ({
            ...prevState,
            start: fromDate,
            end: toDate
        }));
        setSelectedRooms([]);
        setSelectedSurgeons([]);
        setUrgency("");
        setProgress("");
        setStatus("");
        setGender("");
        setAgeRange([0, 120]);
        setShowFirstOp(false);

        setRefetch(true);
    };

    // Handlers
    /**
     * handler for changing disciplines
     * @param {Object} e
     */
    const handleChangeDisciplines = (e) => {
        setSelectedDisciplines(e.target.value);
    };
    const handleChangePlannedDate = (date, name) => {
        setPlannedDate((prevState) => ({
            ...prevState,
            [name]: date
        }));
    };
    const handleChangeRooms = (e) => {
        setSelectedRooms(e.target.value);
    };
    const handleResetRooms = () => {
        setSelectedRooms([]);
    };
    const handleChangeUrgency = (e) => {
        setUrgency(e.target.value);
    };
    const handleResetUrgency = () => {
        setUrgency("");
    };

    const handleChangeAgeRange = (e, index) => {
        setAgeRange((prevState) => {
            const newValue = cloneDeep(prevState);
            newValue[index] = parseInt(e.target.value);
            return newValue;
        });
    };

    const handleChangeStatus = (e) => {
        setStatus(e.target.value);
    };
    const handleResetStatus = () => {
        setStatus("");
    };

    const handleChangeProgress = (e) => {
        setProgress(e.target.value);
    };
    const handleResetProgress = () => {
        setProgress("");
    };

    const handleChangeGender = (e) => {
        setGender(e.target.value);
    };
    const handleResetGender = () => {
        setGender("");
    };

    const toggleOptions = () => {
        onFullOption(!showAllOptions);
        setShowAllOptions(!showAllOptions);
    };

    /**
     * Toggle switch for first op
     */
    const toggleShowFirstOp = () => {
        setShowFirstOp(!showFirstOp);
    };

    const handleEnterAndSearch = (value) => {
        setPatientName(value);
        setRefetch(true);
    };

    const searchTextPatientName = (
        <SearchTextField
            key="search-patient"
            options={uniqBy(allPatientNames, (el) => el.id).sort((a, b) =>
                a.name.localeCompare(b.name, "de", {numeric: true, sensitivity: "base"})
            )}
            placeholder={t("SearchLayer.placeholderPatientName")}
            showSearchIcon
            shrink={true}
            styles={{input: classes.inputPatient, inputWrapper: classes.inputWrapperPatient}}
            title={t("SearchLayer.patientName")}
            value={patientName}
            onChange={setPatientName}
            onDelete={() => setPatientName("")}
            onEnterKey={handleEnterAndSearch}
        />
    );
    const selectMenuDiscipline = (
        <div className={classes.ml1}>
            <DisciplineSelect // @Adrian how can I fix this?
                // @ts-ignore
                multiple
                // @ts-ignore
                styles={{input: classes.input}}
                // @ts-ignore
                values={selectedDisciplines}
                // @ts-ignore
                onSelect={handleChangeDisciplines} // input, placeholder, menu
            />
        </div>
    );
    const dateRangePlannedDate = (
        <DateRange
            max={plannedDate.max}
            min={plannedDate.min}
            styles={{inputDate: classes.dateRangeWidth}}
            title={t("SearchLayer.datePlanned")}
            values={{start: plannedDate.start, end: plannedDate.end}}
            onChange={handleChangePlannedDate}
        />
    );
    const selectMenuRooms = (
        <CommonSelector
            items={roomInfos.map((item) => ({value: item.id, label: item.name}))}
            multiple
            placeholder={t("App.roomPlaceholder")}
            styles={{input: classes.input}}
            title={t("App.roomLabel")}
            value={selectedRooms}
            onChange={handleChangeRooms}
            onReset={handleResetRooms}
        />
    );

    const selectMenuSurgeons = (
        <div className={classes.ml1}>
            <SurgeonsMultiSelect
                // @ts-ignore
                styles={{input: classes.input}}
                // @ts-ignore
                values={selectedSurgeons}
                // @ts-ignore
                onSelect={setSelectedSurgeons}
            />
        </div>
    );
    const selectMenuUrgency = (
        <CommonSelector
            items={prioritiesList.map((item) => ({value: item, label: t(`urgency.${item}`)}))}
            placeholder={t("SearchLayer.urgencyPlaceholder")}
            styles={{input: classes.input}}
            title={t("SearchLayer.urgency")}
            value={urgency}
            onChange={handleChangeUrgency}
            onReset={handleResetUrgency}
        />
    );

    const selectMenuStatus = (
        <div className={classes.ml1}>
            <CommonSelector
                items={listStatus}
                placeholder={t("SearchLayer.opStatusPlaceholder")}
                styles={{input: classes.input}}
                title={t("SearchLayer.opStatus")}
                value={status}
                onChange={handleChangeStatus}
                onReset={handleResetStatus}
            />
        </div>
    );
    const ageArray = [...Array(121).keys()];
    const AgeRangeFrom = (
        <CommonSelector
            disableReset
            items={ageArray.map((item) => ({value: item.toString(), label: item.toString()}))}
            styles={{input: cx(classes.ageRangeWidth, classes.input)}}
            title={t("SearchLayer.patientAgeFrom")}
            value={ageRange[0].toString()}
            onChange={(e) => handleChangeAgeRange(e, 0)}
        />
    );
    const AgeRangeTo = (
        <CommonSelector
            disableReset
            items={ageArray.map((item) => ({value: item.toString(), label: item.toString()}))}
            styles={{input: cx(classes.ageRangeWidth, classes.input)}}
            title={"SearchLayer.patientAgeTo"}
            value={ageRange[1].toString()}
            onChange={(e) => handleChangeAgeRange(e, 1)}
        />
    );
    const selectMenuGender = (
        <div className={classes.ml1}>
            <CommonSelector
                items={listGender}
                placeholder={t("SearchLayer.genderPlaceholder")}
                styles={{input: classes.input}}
                title={t("SearchLayer.gender")}
                value={gender}
                onChange={handleChangeGender}
                onReset={handleResetGender}
            />
        </div>
    );
    const selectMenuProgress = (
        <CommonSelector
            items={listProgress}
            placeholder={t("SearchLayer.opProgressPlaceholder")}
            styles={{input: classes.input}}
            title={t("SearchLayer.opProgress")}
            value={progress}
            onChange={handleChangeProgress}
            onReset={handleResetProgress}
        />
    );
    const switchFirstOp = (
        <div className={classes.switch}>
            <FormControlLabel
                control={<Switch checked={showFirstOp} color="primary" size="small" onChange={toggleShowFirstOp} />}
                label={<div className={classes.label}>{t("SearchLayer.showFirstOp")}</div>}
                labelPlacement="end"
            />
        </div>
    );

    // Check if the date range is valid
    const disabled =
        !plannedDate.start?.isValid ||
        !plannedDate.end?.isValid ||
        plannedDate.start < startOf(plannedDate.min, "day") ||
        plannedDate.start > endOf(plannedDate.max, "day") ||
        plannedDate.end < startOf(plannedDate.min, "day") ||
        plannedDate.end > endOf(plannedDate.max, "day") ||
        plannedDate.end < startOf(plannedDate.start, "day");
    return (
        <div className={classes.filterRoot}>
            <div className={classes.filterRow}>
                {searchTextPatientName}
                {selectMenuDiscipline}
            </div>
            <div className={classes.filterRow}>{dateRangePlannedDate}</div>
            <div className={classes.innerDivider}>
                <div className={classes.toggleText}>
                    <div
                        className={classes.flex}
                        data-testid="showAllOptions"
                        role={"button"}
                        tabIndex={0}
                        onClick={toggleOptions}
                        onKeyDown={(e) => e.key === "Enter" && toggleOptions()}
                    >
                        {showAllOptions ? (
                            <>
                                <ArrowDropUp className={classes.icon} /> {t("App.hideSomeOptions")}
                            </>
                        ) : (
                            <>
                                <ArrowDropDown className={classes.icon} /> {t("App.showAllOptions")}
                            </>
                        )}
                    </div>
                </div>
            </div>
            {showAllOptions && (
                <Fragment>
                    <div className={classes.filterRow}>
                        {selectMenuRooms}
                        {selectMenuSurgeons}
                    </div>
                    <div className={classes.filterRow}>
                        {selectMenuUrgency}
                        {selectMenuStatus}
                    </div>
                    <div className={classes.filterRow}>
                        {AgeRangeFrom}
                        <div className={classes.hyphen}>&#8208;</div>
                        {AgeRangeTo}
                        {selectMenuGender}
                    </div>
                    <div className={classes.filterRow}>
                        {selectMenuProgress}
                        {switchFirstOp}
                    </div>
                </Fragment>
            )}
            <div className={classes.buttonWrapper}>
                <Button className={cx(classes.button, classes.noPaddingLeft)} color="primary" disabled={false} onClick={handleReset}>
                    {t("SearchLayer.reset")}
                </Button>
                <Button
                    className={classes.button}
                    color="primary"
                    data-testid="filterSearchButton"
                    disabled={disabled}
                    variant="contained"
                    onClick={handleSearch}
                >
                    {t("SearchLayer.search")}
                </Button>
            </div>
        </div>
    );
};
SearchFilter.propTypes = {
    onFullOption: PropTypes.func.isRequired,
    searchTrigger: PropTypes.string
};

export default SearchFilter;
