import {makeStyles} from "tss-react/mui";

/** @type {Object} */
export default makeStyles()((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        margin: `0 0 2.5rem 0`,
        display: "flex"
    },
    contentWrapper: {
        color: theme.palette.grey[900],
        width: "100%"
    },
    title: {
        ...theme.custom.rightPanel.title
    },
    divider: {
        ...theme.custom.rightPanel.divider
    },
    innerDivider: {
        height: "2px",
        padding: `0 2.5rem`,
        background: `${theme.palette.grey[100]} 0% 0% no-repeat padding-box`,
        marginTop: theme.spacing("m"),
        marginBottom: theme.spacing("m"),
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    toggleText: {
        display: "flex",
        justifyContent: "center",
        color: theme.palette.primary.main,
        fontSize: "0.75rem",
        background: theme.palette.common.white,
        minWidth: "9rem",
        cursor: "pointer"
    },
    content: {
        marginTop: "1.875rem", // 30px
        marginBottom: "0.675rem", // 10px
        width: "100%",
        fontSize: "0.85rem",
        padding: "0 2.5rem 0 2.5rem"
    },
    buttonWrapper: {
        display: "flex",
        margin: `${theme.spacing("m")} 0`,
        justifyContent: "space-between",
        width: "100%"
    },
    button: {
        fontSize: "0.875rem",
        fontWeight: theme.typography.fontWeightMedium,
        padding: `${theme.spacing("xs")} 1rem`,
        letterSpacing: "0.3px"
    },
    filterRoot: {
        display: "flex",
        flexDirection: "column"
    },
    filterRow: {
        "display": "flex",
        "marginBottom": theme.spacing("m"),
        "& .MuiFormHelperText-root.Mui-error": {
            fontSize: "0.688rem"
        },
        "& .MuiAutocomplete-endAdornment": {
            top: "0.7rem !important" // to align the clear icon to the input strings in patient search
        }
    },
    dateRangeWidth: {
        width: "12.25rem !important"
    },
    inputWrapperPatient: {
        "color": theme.palette.common.black,
        "width": "12.5rem",
        "& svg": {
            fontSize: theme.spacing("m")
        }
    },
    input: {
        "width": "11rem",
        "display": "flex",
        "lineHeight": theme.spacing("m"),
        "& span": {
            maxWidth: "10rem",
            textOverflow: "ellipsis",
            overflow: "hidden"
        },
        "& div": {
            maxWidth: "10rem",
            textOverflow: "ellipsis",
            overflow: "hidden"
        }
    },
    inputPatient: {
        color: theme.palette.common.black,
        width: "80%",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    ml1: {
        marginLeft: theme.spacing("m")
    },
    placeholder: {
        color: theme.palette.grey[500],
        fontSize: "0.875rem"
    },
    closeIcon: {
        fontSize: theme.spacing("m"),
        marginLeft: theme.spacing("s")
    },
    flex: {
        display: "flex"
    },
    icon: {
        color: theme.palette.primary.main,
        fontSize: "1.2rem"
    },
    ageRangeWidth: {
        width: "5rem",
        paddingRight: "0.875rem !important"
    },
    hyphen: {
        margin: `1.5rem ${theme.spacing("xs")} 0`,
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightBold
    },
    result: {
        position: "relative",
        width: "100%"
    },
    opBoxWrapper: {
        "height": "5rem",
        "width": "100%",
        "borderBottom": `2px solid ${theme.palette.border}`,
        "position": "absolute",
        "&:first-of-type": {
            borderTop: `2px solid ${theme.palette.border}`
        },
        "display": "flex"
    },
    resultNumber: {
        color: theme.palette.primary.main,
        fontSize: "0.75rem",
        letterSpacing: "0.4px"
    },
    resultOps: {
        position: "absolute",
        width: "100%"
    },
    dateText: {
        fontSize: "0.6875rem",
        color: theme.palette.text.disabled,
        marginTop: theme.spacing("s")
    },
    actionButton: {
        "zIndex": theme.custom.zIndex.canvas + 15,
        "right": 0,
        "position": "absolute",
        "& .MuiIconButton-root": {
            padding: theme.spacing("xs")
        }
    },
    actionMenuWrapper: {
        margin: theme.spacing("m")
    },
    pointer: {
        cursor: "pointer"
    },
    new: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        opacity: 0.9,
        objectFit: "contain",
        width: "2rem",
        fontWeight: theme.typography.fontWeightMedium,
        left: theme.spacing("s"),
        borderRadius: theme.spacing("xxs"),
        fontSize: "0.75rem",
        textTransform: "uppercase",
        textAlign: "center"
    },
    active: {
        color: theme.palette.text.disabled,
        opacity: 0.9,
        textAlign: "center",
        width: "2rem",
        fontWeight: theme.typography.fontWeightLight,
        fontSize: "0.75rem",
        textTransform: "uppercase"
    },
    actionMenu: {
        display: "flex",
        alignItems: "center",
        fontSize: "0.75rem",
        color: theme.palette.grey[700],
        margin: `${theme.spacing("xs")} 0`
    },
    selected: {
        background: theme.palette.primary.light
    },
    disabled: {
        // backgroundColor: "rgba(0, 0, 0, 0.12)",
        color: "rgba(0, 0, 0, 0.26)",
        cursor: "default"
    },
    menu: {
        minWidth: "16rem",
        minHeight: "3rem",
        position: "absolute",
        right: "2rem",
        zIndex: theme.zIndex.modal,
        background: theme.palette.background.paper,
        borderRadius: "5px",
        boxShadow: theme.shadows[2],
        whiteSpace: "nowrap"
    },
    unvisible: {
        display: "none"
    },
    noPaddingLeft: {
        paddingLeft: 0
    },
    switch: {
        width: "12.5rem",
        marginLeft: "1.5rem",
        marginTop: theme.spacing("m")
    },
    label: {
        fontSize: "0.75rem",
        color: theme.palette.grey[700]
    },
    option: {
        fontSize: "0.875rem"
    },
    detailDialogRoot: {
        maxWidth: "1100px",
        maxHeight: "fit-content",
        borderRadius: 0
    }
}));
