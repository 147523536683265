import {makeStyles} from "tss-react/mui";

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles()(() => ({
    badge: {
        fontSize: 1,
        height: "12px",
        minWidth: "10px",
        width: "10px"
    },
    dotPosition: {
        top: "19%",
        right: "22%"
    }
}));
