// @ts-check
import {Checkbox, FormControlLabel} from "@mui/material";
import {bool, func, object, shape, string} from "prop-types";
import React from "react";
import {Controller} from "react-hook-form";

/**
 * @typedef {Object} CheckBoxClasses
 * @property {string} [label]
 * @property {string} [root]
 * @property {string} [checkbox]
 */

/**
 * render the controlled checkbox
 *
 * @param {object} props
 * @param {Function} props.cx
 * @param {Control} props.control
 * @param {boolean} [props.disabled=false]
 * @param {string} props.name
 * @param {string} props.label
 * @param {"small" | "medium"} [props.size="small"]
 * @param {("end" | "start" | "top" | "bottom")} [props.labelPlacement="end"]
 * @param {CheckBoxClasses} [props.classesStyle]
 * @return {React.ReactElement}
 */
export const ControlledCheckbox = ({cx, control, classesStyle, name, label, size = "small", labelPlacement = "end", disabled = false}) => {
    return (
        <FormControlLabel
            classes={{root: cx(classesStyle && classesStyle.root), label: cx(classesStyle && classesStyle.label)}}
            control={
                <Controller
                    control={control}
                    name={name}
                    render={({field: {value, onChange}}) => {
                        return <Checkbox checked={value} disabled={disabled} name={name} size={size} onChange={onChange} />;
                    }}
                />
            }
            disabled={disabled}
            label={label}
            labelPlacement={labelPlacement}
        />
    );
};
ControlledCheckbox.propTypes = {
    cx: func.isRequired,
    control: object.isRequired,
    disabled: bool,
    classesStyle: shape({
        root: string,
        label: string,
        checkbox: string
    }),
    name: string.isRequired,
    label: string.isRequired,
    labelPlacement: string,
    size: string
};
