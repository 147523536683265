import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => {
    const drawerWidth = theme.custom.menuWidth;

    return {
        drawer: {
            "width": drawerWidth,
            "flexShrink": 0,
            "borderLeft": "none",
            "position": "fixed",
            "top": theme.custom.headerHeight,
            "height": `calc(100% - ${theme.custom.headerHeight})`,
            "right": 0,
            "zIndex": theme.custom.zIndex.canvas + 500,
            "& .MuiButton-root:hover": {
                backgroundColor: theme.palette.common.white
            }
        },
        drawerFullWidth: {
            width: "9.25rem"
        },
        drawerWithTeaser: {
            top: "78px",
            height: "calc(100% - 78px)"
        },
        drawerPaper: {
            position: "absolute",
            padding: `${theme.spacing("m")} 0`,
            backgroundColor: theme.palette.common.white,
            border: "none",
            width: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            height: "100%",
            boxShadow: theme.shadows[2]
        },
        fullWidthButton: {
            width: "9.25rem",
            padding: `0.125rem 1rem`,
            justifyContent: "start"
        },
        minWidthButton: {
            "borderRadius": 0,
            "padding": theme.spacing("xxs"),
            "& .MuiButton-startIcon": {
                margin: 0
            }
        },
        isOpened: {
            "& .MuiSvgIcon-root": {
                backgroundColor: theme.palette.primary.light
            }
        },
        isOpenedAndFillColor: {
            "& path": {
                fill: theme.palette.primary.main
            }
        },
        toggleWrapper: {
            position: "absolute",
            bottom: theme.spacing("s")
        },
        label: {
            fontSize: "0.75rem",
            textTransform: "capitalize",
            color: theme.palette.text.primary,
            textAlign: "start"
        },
        toggleIcon: {
            transform: "matrix(0, 1, -1, 0, 0, 0)",
            color: theme.palette.grey[700]
        },
        icon: {
            "width": "2.5rem",
            "height": "2.5rem",
            "borderRadius": "1.25rem",
            "padding": theme.spacing("s"),
            "&:hover": {
                backgroundColor: theme.palette.primary.light
            }
        },
        iconDefault: {
            color: theme.palette.grey[700]
        },
        iconError: {
            color: theme.palette.error.main
        },
        iconDisabled: {
            color: theme.palette.text.disabled
        },
        disabled: {
            "& path": {
                fill: theme.palette.grey[200]
            }
        }
    };
});
