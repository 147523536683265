import {Close} from "@mui/icons-material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";

import useStyles from "./confirm_dialog.styles";

const ConfirmDialog = ({children, open, title, onConfirm, onCancel}) => {
    const {t} = useTranslation();
    const {classes} = useStyles();

    return (
        <Dialog
            aria-labelledby="confirm-dialog-title"
            maxWidth="md"
            open={open}
            PaperProps={{
                elevation: 3,
                classes: {root: classes.wrapper}
            }}
            onClose={onCancel}
        >
            <Close className={classes.closeIcon} onClick={onCancel} />
            <DialogTitle id="confirm-dialog-title">{title || t("ConfirmDialog.title")}</DialogTitle>
            <DialogContent>{children || t("DeleteConfirmation.text")}</DialogContent>
            <DialogActions>
                <Button size="small" variant="outlined" onClick={onCancel}>
                    {t("ConfirmDialog.cancel")}
                </Button>
                <Button color="primary" data-testid="confirmDeleteButtonPopUp" size="small" variant="contained" onClick={onConfirm}>
                    {t("ConfirmDialog.confirm")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ConfirmDialog.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default ConfirmDialog;
