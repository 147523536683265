import PropTypes from "prop-types";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {selectSearchOps, selectSearchStatus} from "../../pages/op_management/op_management_selectors";
import {selectCurrentOrganizationId} from "../../redux/app_selectors";
import {isPending} from "../../redux/utils/status";
import {loadEmployeesAction} from "../employees/employees_actions";
import InfoBlock from "../shared/info_block/info_block";
import Loading from "../shared/loading/loading";
import SearchFilter from "./components/search_filter";
import SearchResult from "./components/search_result";
import useStyles from "./search_layer.styles";

const HEADER_HEIGHT = 48 + 98 + 75; // header 48 + layer header 98 + result header 75

export const SearchLayer = ({onOpenDetails, searchTrigger, isBlockscreenVisible}) => {
    const {classes} = useStyles();
    const {t} = useTranslation();
    const ref = useRef();
    const dispatch = useDispatch();

    // Redux
    const searchOps = useSelector(selectSearchOps);
    const searchStatus = useSelector(selectSearchStatus);
    const organizationId = useSelector(selectCurrentOrganizationId);

    // States
    const [height, setHeight] = useState([]);
    const [filterHeight, setFilterHeight] = useState(78); // default: 78, openFilter: 300, full: 552

    // Set event listener for resize
    useEffect(() => {
        const resizeListener = () => {
            // change width from the state object
            setHeight(getHeight());
        };
        resizeListener();
        // set resize listener
        window.addEventListener("resize", resizeListener);

        // clean up function
        return () => {
            // remove resize listener
            window.removeEventListener("resize", resizeListener);
        };
    }, []);

    useEffect(() => {
        dispatch(loadEmployeesAction(organizationId));
    }, [organizationId]);

    const getHeight = () => {
        if (ref.current) {
            return ref.current.clientHeight;
        }
        return null;
    };

    const actionPending = isPending(searchStatus);

    const handleFilterToggle = (open) => {
        if (open) {
            setFilterHeight(300);
        } else {
            setFilterHeight(78);
        }
    };
    const handleFullOption = (full) => {
        if (full) {
            setFilterHeight(552);
        } else {
            setFilterHeight(300);
        }
    };
    return (
        <div className={classes.root} ref={ref}>
            <div className={classes.contentWrapper}>
                <div className={classes.title}>
                    <div>{t("SearchLayer.title")}</div>
                </div>
                <div className={classes.divider} />
                <div className={classes.content}>
                    <InfoBlock keepMounted title={t("SearchLayer.filter")} onToggle={handleFilterToggle}>
                        <SearchFilter searchTrigger={searchTrigger} onFullOption={handleFullOption} />
                    </InfoBlock>
                </div>
                <div className={classes.divider} />
                <div className={classes.content}>
                    <InfoBlock
                        defaultOpen
                        title={t("SearchLayer.resultNumber", {
                            count: searchOps.length
                        })}
                    >
                        {actionPending ? (
                            <div style={{height: height - HEADER_HEIGHT - filterHeight}}>
                                <Loading />
                            </div>
                        ) : (
                            <SearchResult
                                height={height - HEADER_HEIGHT - filterHeight}
                                isBlockscreenVisible={isBlockscreenVisible}
                                resultOps={searchOps}
                                onOpenDetails={onOpenDetails}
                            />
                        )}
                    </InfoBlock>
                </div>
            </div>
        </div>
    );
};
SearchLayer.propTypes = {
    onOpenDetails: PropTypes.func.isRequired,
    searchTrigger: PropTypes.string,
    isBlockscreenVisible: PropTypes.bool.isRequired
};
export default SearchLayer;
