// @ts-check
import {FormControl, FormHelperText, InputLabel, TextField} from "@mui/material";
import {TimePicker} from "@mui/x-date-pickers";
import isEmpty from "lodash/isEmpty";
import {bool, object, string} from "prop-types";
import React, {useContext} from "react";
import {Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {DateContext} from "../../../../contexts/dates";
import useStyles from "../controlled_form_styles";

/**
 * Controlled Time Picker
 *
 * @param {object} props
 * @param {object} props.control
 * @param {string} props.name
 * @param {string} props.inputFormat
 * @param {string} props.label
 * @param {boolean} [props.required]
 * @param {boolean} [props.disabled]
 * @param {string} props.label
 * @param {object} [props.styles]
 * @param {boolean} [props.disableOpenPicker]
 * @param {boolean} [props.hasOwnErrorMessage=false]
 * @param {boolean} [props.readOnly=false]
 * @param {object} [props.errors]
 * @return {React.ReactElement}
 */
export const ControlledTimePicker = ({
    control,
    name,
    inputFormat,
    label,
    required,
    disabled,
    styles,
    disableOpenPicker,
    hasOwnErrorMessage = true,
    readOnly = false,
    errors
}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const hasError = !isEmpty(errors);
    const {fromJSDate} = useContext(DateContext);

    const inputLabel = (
        <InputLabel id={`time-picker-label-${name}`} required={required} shrink>
            {label}
        </InputLabel>
    );
    return (
        <FormControl error={hasError} variant="standard">
            <Controller
                control={control}
                defaultValue={null}
                name={name}
                render={({field: {value, onChange}}) => {
                    return (
                        <TimePicker
                            ampm={false}
                            disabled={disabled}
                            disableMaskedInput={true}
                            disableOpenPicker={disableOpenPicker}
                            InputAdornmentProps={{position: "start"}}
                            inputFormat={inputFormat}
                            label={inputLabel}
                            renderInput={(props) => (
                                // @ts-ignore
                                <TextField
                                    variant="standard"
                                    {...props}
                                    classes={{
                                        root: cx(classes.inputDate, {
                                            [styles.inputDate]: styles.inputDate
                                        })
                                    }}
                                    error={hasError}
                                    inputProps={{
                                        // eslint-disable-next-line react/prop-types
                                        ...props.inputProps,
                                        "placeholder": t("App.pleaseSelect"),
                                        "aria-labelledby": `time-picker-label-${name}`,
                                        readOnly
                                    }}
                                />
                            )}
                            value={fromJSDate(value)}
                            onChange={(date) => {
                                onChange((date && date.toJSDate()) || date);
                            }}
                        />
                    );
                }}
            />
            {hasError && hasOwnErrorMessage && <FormHelperText className={classes.error}>{t(errors.message)}</FormHelperText>}
        </FormControl>
    );
};
ControlledTimePicker.propTypes = {
    hasError: bool,
    control: object.isRequired,
    name: string.isRequired,
    disabled: bool,
    label: string.isRequired,
    required: bool,
    minDate: object,
    maxDate: object,
    inputFormat: string.isRequired,
    styles: object,
    disableOpenPicker: bool,
    hasOwnErrorMessage: bool,
    readOnly: bool,
    errors: object
};
