// @ts-check
import {TextField} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import useStyles from "./common_text_field.styles";

/**
 * Common TextField
 * @param {Object} props
 * @param {string} props.value
 * @param {ChangeEventHandler} props.onChange
 * @param {string} [props.title]
 * @param {boolean} props.multiline
 * @param {number} props.minRows
 * @param {"outlined" | "filled" | "standard"} [props.variant="outlined"]
 * @param {object} [props.inputProps]
 * @return {React.ReactElement}
 */
export const CommonTextField = ({value, onChange, title, multiline, minRows, variant = "outlined", inputProps}) => {
    const {classes} = useStyles();

    return (
        <TextField
            data-testid="textFieldForm"
            fullWidth={true}
            InputLabelProps={{shrink: true}}
            InputProps={{
                classes: {
                    multiline: classes.textField,
                    input: classes.inputText
                }
            }}
            inputProps={inputProps}
            label={title}
            minRows={minRows}
            multiline={multiline}
            type="text"
            value={value}
            variant={variant}
            onChange={onChange}
        />
    );
};

CommonTextField.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    title: PropTypes.string,
    multiline: PropTypes.bool,
    minRows: PropTypes.number.isRequired,
    variant: PropTypes.string,
    inputProps: PropTypes.object
};
export default CommonTextField;
