import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    textField: {
        "paddingBottom": theme.spacing("s"),
        "paddingTop": theme.spacing("s"),
        "&  span": {
            marginRight: "-1rem"
        }
    },
    inputText: {
        fontSize: "0.875rem",
        backgroundColor: theme.palette.background.layer,
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px"
    }
}));
