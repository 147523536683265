// @ts-check
import {VerticalAlignBottom, VerticalAlignTop} from "@mui/icons-material";
import {Button, Drawer, Tooltip} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {selectShowTeaser} from "../../../redux/events/event_selectors";
import useStyles from "./action_menubar.styles";
import iconMapping from "./icon_mapping_actions";

const excludeIconNamesForFill = ["AbsenceIcon", "AvailableIcon"];

/**
 * render action menubar component
 *
 * @param {object} props
 * @param {Array<ActionMenu>} props.actions
 * @param {boolean} props.showFullActionMenubar
 * @param {MouseEventHandler} props.onToggleWidth
 * @return {React.ReactElement}
 */
export const ActionMenubar = ({actions, showFullActionMenubar, onToggleWidth}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();

    const showTeaser = useSelector(selectShowTeaser);

    const actionButtons = actions.map((action) => {
        const IconName = iconMapping[action.iconName] || iconMapping.Default;
        const actionButton = (
            <div data-testid={`actionItem-${action.translationKey}`} key={action.translationKey}>
                <Button
                    className={cx({
                        [classes.fullWidthButton]: showFullActionMenubar,
                        [classes.minWidthButton]: !showFullActionMenubar,
                        [classes.isOpened]: action.isOpened,
                        [classes.isOpenedAndFillColor]: action.isOpened && !excludeIconNamesForFill.includes(action.iconName),
                        [classes.disabled]: action.disabled
                    })}
                    disabled={action.disabled}
                    startIcon={
                        <IconName
                            className={cx(classes.icon, {
                                [classes.iconDefault]: !action.isOpened,
                                [classes.iconDisabled]: action.disabled,
                                [classes.iconError]: action.iconColor === "error"
                            })}
                        />
                    }
                    onClick={action.handler}
                >
                    <div className={classes.label}>{showFullActionMenubar && t(action.translationKey)}</div>
                </Button>
            </div>
        );
        return showFullActionMenubar ? (
            actionButton
        ) : (
            <Tooltip arrow key={action.translationKey} placement="left" title={t(action.translationKey)}>
                {actionButton}
            </Tooltip>
        );
    });

    const toggleButton = (
        <Button
            className={cx({
                [classes.fullWidthButton]: showFullActionMenubar,
                [classes.minWidthButton]: !showFullActionMenubar
            })}
            key="toggle"
            startIcon={
                showFullActionMenubar ? (
                    <VerticalAlignTop className={cx(classes.toggleIcon, classes.icon)} />
                ) : (
                    <VerticalAlignBottom className={cx(classes.toggleIcon, classes.icon)} />
                )
            }
            onClick={onToggleWidth}
        >
            <div className={classes.label}>{showFullActionMenubar && t("OpManagementActions.close")}</div>
        </Button>
    );
    return (
        <Drawer
            anchor="right"
            classes={{
                paper: classes.drawerPaper
            }}
            className={cx(classes.drawer, {
                [classes.drawerFullWidth]: showFullActionMenubar,
                [classes.drawerWithTeaser]: showTeaser
            })}
            variant="permanent"
        >
            {actionButtons}
            <div className={classes.toggleWrapper}>{toggleButton}</div>
        </Drawer>
    );
};
ActionMenubar.propTypes = {
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            iconName: PropTypes.string.isRequired,
            handler: PropTypes.func.isRequired,
            translationKey: PropTypes.string,
            isOpened: PropTypes.bool,
            disabled: PropTypes.bool
        }).isRequired
    ).isRequired,
    showFullActionMenubar: PropTypes.bool,
    onToggleWidth: PropTypes.func.isRequired
};

export default ActionMenubar;
