// @ts-check
import {FormControlLabel, Switch} from "@mui/material";
import {bool, func, object, string} from "prop-types";
import React from "react";
import {Controller} from "react-hook-form";

/**
 *
 * @param {object} props
 * @param {Function} props.cx
 * @param {object} props.control
 * @param {boolean} props.switchDisabled
 * @param {string} props.name
 * @param {string} props.label
 * @param {("end" | "start" | "top" | "bottom")} props.labelPlacement
 * @param {object} [props.classesStyle]
 * @return {React.ReactElement}
 */
export const ControlledSwitch = ({cx, control, switchDisabled, classesStyle, name, label, labelPlacement}) => {
    return (
        <FormControlLabel
            classes={{label: cx(classesStyle && classesStyle)}}
            control={
                <Controller
                    control={control}
                    name={name}
                    render={({field: {value, onChange}}) => {
                        return (
                            <Switch
                                checked={value}
                                color="primary"
                                disabled={switchDisabled}
                                size="small"
                                value={value}
                                onChange={(e) => onChange(e.target.checked)}
                            />
                        );
                    }}
                />
            }
            disabled={switchDisabled}
            label={label}
            labelPlacement={labelPlacement}
        />
    );
};
ControlledSwitch.propTypes = {
    cx: func.isRequired,
    control: object.isRequired,
    switchDisabled: bool.isRequired,
    classesStyle: string,
    name: string,
    label: string,
    labelPlacement: string
};
