import {
    AccessTime,
    AssignmentTurnedIn,
    DateRange,
    FormatListBulleted,
    LineStyle,
    List as ListIcon,
    LocalHospital,
    MenuBook,
    Print,
    Search,
    Undo,
    ViewModule
} from "@mui/icons-material";

import AbsenceIcon from "../../shared/icons/absence_icon";
import AllowPrintIcon from "../../shared/icons/allow_print_icon";
import AvailableIcon from "../../shared/icons/available_icon";
import InfoIcon from "../../shared/icons/info_icon";

export default {
    AbsenceIcon,
    AccessTime,
    AssignmentTurnedIn,
    AvailableIcon,
    DateRange,
    FormatListBulleted,
    LineStyle,
    MenuBook,
    Print,
    Search,
    Undo,
    ViewModule,
    LocalHospital,
    InfoIcon,
    AllowPrintIcon,
    Default: ListIcon
};
