// @ts-check
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {IconButton, InputAdornment} from "@mui/material";
import {bool, func} from "prop-types";
import React from "react";

import useStyles from "./adornment_modified.styles";

/**
 * A modified adornment to handle the icon change on password inputs
 * @param {object} props
 * @param {function} props.handler The handler for the icon
 * @param {boolean} props.isVisible Wether the icon should express that the field is visible
 * @param {boolean} [props.isModifyAllowed=true] Wether the user has permits or not
 * @param {boolean} [props.hasError=false] Wether there is an error on the input that holds the adornment
 * @returns {JSX.Element} A icon adornment for the passwords inputs
 */

export const AdornmentModified = ({handler, isVisible, isModifyAllowed = true, hasError = false}) => {
    const {classes, cx} = useStyles();

    return (
        <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" disabled={!isModifyAllowed} size="large" onClick={() => handler()}>
                {isVisible ? (
                    <Visibility
                        className={cx({
                            [classes.error]: hasError
                        })}
                        color={isModifyAllowed ? "primary" : "disabled"}
                    />
                ) : (
                    <VisibilityOff
                        className={cx({
                            [classes.error]: hasError
                        })}
                        color={isModifyAllowed ? "primary" : "disabled"}
                    />
                )}
            </IconButton>
        </InputAdornment>
    );
};

AdornmentModified.propTypes = {
    handler: func.isRequired,
    isVisible: bool.isRequired,
    isModifyAllowed: bool,
    hasError: bool
};
