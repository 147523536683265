// @ts-check
import {Close, Search} from "@mui/icons-material";
import {FormControl, FormHelperText, InputAdornment, InputLabel, TextField} from "@mui/material";
import {Autocomplete} from "@mui/material";
import {array, bool, object, string} from "prop-types";
import React from "react";
import {Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";

import useStyles from "../controlled_form_styles";
/** @typedef {import('react-hook-form').FieldError} FieldError */

/**
 * A reusable controlled MUI Autocomplete for single values to use with RHF
 *
 * @param {object} props
 * @param {FieldError} [props.errors] RHF's control object
 * @param {object} props.control RHF's control object
 * @param {Array<ItemType>} props.items RHF's control object
 * @param {string} props.name The Controller name for RHF
 * @param {string} props.title The Input's title
 * @param {string} [props.placeholder=""] The Input's placeholder
 * @param {string} [props.classesStyle] The optional className for the FormControl
 * @param {boolean} [props.disabled=false] Wether the autocomplete is disabled
 * @param {boolean} [props.hasOwnErrorMessage=false] Whether to show own error message
 * @param {boolean} [props.allowFreeText=true] It allows to input the value out of the given options
 * @param {boolean} [props.showSearchIcon=false] It allows to show the search icon at the start of the text field
 * @return {React.ReactElement}
 */
export const ControlledAutocomplete = ({
    errors,
    control,
    items,
    name,
    title,
    placeholder = "",
    classesStyle,
    disabled = false,
    hasOwnErrorMessage = false,
    allowFreeText = true,
    showSearchIcon = false
}) => {
    const {t} = useTranslation();
    const hasError = Boolean(errors);

    const {classes, cx} = useStyles();
    return (
        <FormControl classes={{root: cx(classesStyle && classesStyle)}} error={hasError} variant="standard">
            <InputLabel shrink>{title}</InputLabel>
            <Controller
                control={control}
                name={name}
                render={({field: {value, onChange}}) => (
                    <Autocomplete
                        autoHighlight
                        classes={{
                            noOptions: classes.noOptions,
                            root: classes.fullHeight,
                            inputRoot: classes.fullHeight,
                            input: classes.fullHeight,
                            endAdornment: classes.endAdornment,
                            paper: classes.menuWrapperAutocomplete
                        }}
                        clearIcon={<Close className={classes.clearIcon} data-testid="autocomplete-detele-icon" />}
                        clearOnBlur
                        closeText={t("App.close")}
                        disabled={disabled}
                        freeSolo
                        getOptionLabel={(option) => {
                            if (option.label) return option.label ? option.label : "";
                            return option;
                        }}
                        isOptionEqualToValue={(option, value) => option.value === value}
                        openText={t("App.open")}
                        options={allowFreeText ? items.filter((item) => item.label.toLowerCase().includes(value?.toLowerCase())) : items}
                        renderInput={(params) => {
                            // @ts-ignore
                            const {value} = params.inputProps;
                            const optionSelected = items.find((item) => item.value === value);
                            return (
                                <TextField
                                    variant="standard"
                                    {...params}
                                    className={classes.inputAutocomplete}
                                    error={hasError}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: showSearchIcon && (
                                            <InputAdornment data-testid="autocomplete-search-icon" position="start">
                                                <Search color="primary" fontSize="small" />
                                            </InputAdornment>
                                        )
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        "value": optionSelected ? optionSelected?.label : value,
                                        "data-testid": "autocomplete-" + name
                                    }}
                                    placeholder={placeholder || t("App.pleaseSelect")}
                                />
                            );
                        }}
                        renderOption={(props, option, {selected}) => (
                            <li {...props} key={option.id || option.value}>
                                {option.label}
                            </li>
                        )}
                        value={value}
                        onChange={(e, selected) => {
                            if (selected === null) {
                                return onChange(null);
                            }
                            return onChange(selected.value);
                        }}
                        onInputChange={(e, value, reason) => {
                            if (allowFreeText && reason !== "reset") {
                                onChange(value);
                            }
                        }}
                    />
                )}
            />
            {hasError && hasOwnErrorMessage && <FormHelperText className={classes.error}>{t(errors.message)}</FormHelperText>}
        </FormControl>
    );
};
ControlledAutocomplete.propTypes = {
    errors: object,
    control: object.isRequired,
    items: array.isRequired,
    name: string.isRequired,
    title: string.isRequired,
    placeholder: string,
    classesStyle: string,
    disabled: bool,
    hasOwnErrorMessage: bool,
    allowFreeText: bool,
    showSearchIcon: bool
};
